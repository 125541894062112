/*
──────────────────────────────────────────
──────────────────────────────────────────
Viewport
──────────────────────────────────────────
──────────────────────────────────────────
const windowHeight = Viewport.height;
*/

const Viewport = {
  get width() {
    return innerWidth;
  },

  get height() {
    return innerHeight;
  },

  get devicePixelRatio() {
    return Math.min(devicePixelRatio || 1, 2);
  }
};

export default Viewport;
