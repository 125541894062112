import anime from "animejs";

import Delay from "@utils/delay";
import { $, $$, html, rect } from "@utils/dom";
import { lerp } from "@utils/math";
import Viewport from "@utils/viewport";

const SELECTOR = "[data-site-loader]";

function inViewport(el) {
  if (!el) return false;

  const { top } = rect(el);
  return top <= Viewport.height;
}

class SiteLoader {
  constructor() {
    this.el = $(SELECTOR);
    this.progress = $('.site-loader__progress', this.el);

    this._tween = { cx: 0.0, cy: 1.0, tx: 0.2, ty: 1.0, lerpX: 0.05, lerpY: 0.2 };
    this._raf = null;
    this._enterCompleted = false;

    this._onEnterCompleted = this._onEnterCompleted.bind(this);
    this._updateProgressBar = this._updateProgressBar.bind(this);

    this._delay = new Delay(this._onEnterCompleted, 1050 + 7.5 * 45); // duration of logo animateIn
  }

  init() {
    // start progress bar RAF
    this._raf = requestAnimationFrame(this._updateProgressBar);

    // listen for enter animation completion
    this._delay.start();
  }

  loaded() {
    // increment --row-delay css variable to each .pb-row-wrapper[data-scroll-section] in viewport during initialization
    [ ...$$(`main .pb-row-wrapper[data-scroll-section]`) ].forEach((el, index) => {
      if (inViewport(el)) el.style.setProperty("--row-delay", `${index * 250 + 250}ms`);
    });

    // images are loaded (35%)
    this._tween.tx = 0.35;
  }

  beforeReady() {
    // modules/ui are pre-initialized (85%)
    this._tween.tx = 0.85;

    return new Promise(resolve => {
      if( this._enterCompleted ) resolve();
      else this._beforeReadyPromise = resolve;
    });
  }

  ready() {
    // modules/ui are loaded and initialized (100%)
    this._tween.tx = 1.0;
    this._tween.ty = 0.0;
    this._tween.lerpX = 0.1;
    this._tween.lerpY = 0.05;

    // exit animation
    //this.el.classList.add('--exit');
    
    return new Promise(resolve => {
      html.classList.add('--js-windmill-ready');

      anime({
        targets: this.el,
        opacity: 0,
        duration: 250,
        easing: "linear",
        complete: () => {
          // remove from DOM when completed
          this.el.parentNode.removeChild(this.el);

          // stop progress bar RAF
          if( this._raf ) cancelAnimationFrame(this._raf);
          this._raf = null;

          this._beforeReadyPromise = null;
          this._enterCompleted = null;
          this._tween = null;

          // resolve transition
          resolve();
        }
      });
    });
  }

  _onEnterCompleted() {
    this._enterCompleted = true;
    if( this._beforeReadyPromise ) this._beforeReadyPromise();
  }
  _updateProgressBar() {
    this._tween.cx = lerp(this._tween.cx, this._tween.tx, this._tween.lerpX);
    this._tween.cy = lerp(this._tween.cy, this._tween.ty, this._tween.lerpY);

    this.progress.style.transform = `scale(${this._tween.cx}, ${this._tween.cy})`;

    if( this._tween.tx === 1.0 ) {
      const xCompleted = this._tween.tx - this._tween.cx <= 0.001;
      const yCompleted = this._tween.ty - this._tween.cy <= 0.001;

      if( xCompleted && yCompleted ) {
        this._raf = null;
        return;
      }
    }

    this._raf = requestAnimationFrame(this._updateProgressBar);
  }
}

export default SiteLoader;
