import { STATE } from "@core/state";
import { EXIT_NAV_DELAY, EXIT_PAGE_DURATION } from '@core/variables.js';
import { instance as SiteScroll } from "@ui/site-scroll";
import Delay from "@utils/delay";
import { $, $$, rect } from "@utils/dom";
import { lerp } from "@utils/math";
import { sleep } from "@utils/sleep";
import { getTranslate } from "@utils/transform";
import Viewport from "@utils/viewport";

const SELECTOR = "[data-site-transition]";
const WINDMILL_WRAPPER = '[data-windmill="wrapper"]';

const DELAY_BEFORE_SHOWING_PROGRESS_BAR = 500;

class SiteTransition {
  constructor() {
    this.container = $(WINDMILL_WRAPPER);
    this.el = $(SELECTOR);
    this.progress = $('.site-transition__progress', this.el);

    this._tween = { cx: 0.2, cy: 0, tx: 0.2, ty: 0, lerpX: 0.1, lerpY: 0.2 };
    this._raf = null;

    this._updateProgressBar = this._updateProgressBar.bind(this);
    this._onExitDelay = this._onExitDelay.bind(this);

    this._delay = new Delay(this._onExitDelay, DELAY_BEFORE_SHOWING_PROGRESS_BAR);
  }

  exit() {
    if( this._raf ) cancelAnimationFrame(this._raf);
    this._raf = null;

    // reset progress bar tween params
    this._tween.cx = this._tween.cy = this._tween.tx = this._tween.ty = 0;
    this._tween.lerpX = 0.1;
    this._tween.lerpY = 0.2;

    // hide progress bar
    this.progress.style.transform = `scale(${this._tween.cx}, ${this._tween.cy})`;

    // show transparent panel to disable click during transition
    this.el.classList.remove("pointer-events-none", "visibility-hidden");

    // get scrollY + viewport height
    const scrollY = SiteScroll.y;
    const vh = Viewport.height;

    // filter sections that are in viewport
    const sections = [ ...$$('[data-scroll-section]:not(.site-spacer)', this.container) ].filter(section => {
      const { top, height } = rect(section);
      const { y } = getTranslate(section);

      const untransformedTop = top - y - scrollY;
      const bottom = untransformedTop + height;

      // if section's top is below viewport's bottom, he's out of viewport
      if( untransformedTop > vh ) return false;

      // if section's bottom is above viewport's top, he's out of viewport
      if( bottom < 0 ) return false;

      // section is in viewport
      return true;
    });

    // exit all sections that are in viewport
    sections.forEach(section => section.classList.add("site-transition--exit"));

    // wait and return promise
    return sleep(STATE.siteNavOpen ? EXIT_NAV_DELAY + EXIT_PAGE_DURATION : EXIT_PAGE_DURATION);
  }
  
  exited() {
    // hide windmill's wrapper before adding new page content
    this.container.classList.add("visibility-hidden");

    // wait for [DELAY_BEFORE_SHOWING_PROGRESS_BAR] milliseconds 
    // and show loading progression for better UX
    this._delay.reset();
    this._delay.start();
  }

  fetched() { this._tween.tx = 0.35; }
  entering() { this._tween.tx = 0.75; }

  enter() {
    // stop delay
    this._delay.stop();

    // set progress bar as fully loaded
    this._tween.tx = 1.0;
    this._tween.ty = 0.0;
    this._tween.lerpY = 0.05;

    // show windmill's wrapper
    this.container.classList.remove("visibility-hidden");

    // disable click on transition panel
    this.el.classList.add("pointer-events-none");
  }

  _onExitDelay() {
    this._tween.tx = 0.2;
    this._tween.ty = 1.0;

    this._raf = requestAnimationFrame(this._updateProgressBar);
  }
  _updateProgressBar() {
    this._tween.cx = lerp(this._tween.cx, this._tween.tx, this._tween.lerpX);
    this._tween.cy = lerp(this._tween.cy, this._tween.ty, this._tween.lerpY);

    this.progress.style.transform = `scale(${this._tween.cx}, ${this._tween.cy})`;

    if( this._tween.tx === 1.0 ) {
      const xCompleted = this._tween.tx - this._tween.cx <= 0.01;
      const yCompleted = this._tween.ty - this._tween.cy <= 0.01;

      if( xCompleted ) this._tween.lerpX = 0.1;
      if( xCompleted && yCompleted ) {
        // hide transition panel
        this.el.classList.add("visibility-hidden");

        this._raf = null;
        return;
      }
    }

    this._raf = requestAnimationFrame(this._updateProgressBar);
  }
}

export default SiteTransition;
